import React, { useRef, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  isMobile,
  isMobileOnly,
  isTablet,
  useMobileOrientation,
} from 'react-device-detect';
import st from './main.module.scss';
import FirstPage from './FirstPage';
import Sustainability from './Sustainability';
import VideoComponent from './VideoComponent';
import Housing from './Housing';
import Feeding from './Feeding';
import Milking from './Milking';
import Transport from './Transport';
import { STORE } from './../context';
import Congrats from './Congrats';
import ButterflyFlying from './ButterflyFlying';
import ButterflyNew from './ButterflyNew';
import MediaPopup from './MediaPopup';
import newMainImage from '../assets/logo11Updated.png';
import anime from 'animejs';

const Main = (props: any) => {
  const { user } = useContext(STORE);
  const { isPortrait } = useMobileOrientation();
  const [scene, setScene] = useState('main');
  const [isVideo, setVideo] = useState(false);
  const [videoSrc, setVideoSrc] = useState<string>('');
  const [mediaResource, setMediaResource] = useState<any>('');
  const [isCongrats, setCongrats] = useState(false);
  const [isPlayAll, setPlayAll] = useState(false);
  const [isActive, setActive] = useState(true);
  const [originSize, setOriginSize] = useState(0);
  const [bgSize, setBgSize] = useState('100%');
  const [bgImage, setBgImage] = useState<any>({ width: 0, height: 0 });
  const image = useRef<any | null>(null);
  const container = useRef<any | null>(null);
  let navigate = useNavigate();

  useEffect(() => {
    navigate('');
    document.title = 'Dairy Tour 360 | Virtual Farm Experience';

    var imageLoad = new Image();
    imageLoad.src = isMobileOnly
      ? '../assets/DairyMax_Final_Mobile.jpg'
      : '../assets/DairyMax_Final.jpg';
    imageLoad.onload = function () {
      setBgImage({ width: imageLoad.width, height: imageLoad.height });
    };
  }, []);

  useEffect(() => {
    const detectSizes = () => {
      let ratioWidth = image.current.clientWidth / bgImage.width;
      let ratioHeight = image.current.clientHeight / bgImage.height;

      if (ratioWidth > ratioHeight) {
        setOriginSize(100);
      } else {
        setOriginSize((ratioHeight * 100) / ratioWidth);
      }
    };

    if ((bgImage.width, bgImage.height)) {
      detectSizes();
      window.addEventListener('resize', () => {
        detectSizes();
      });
    }

    return () => {
      window.removeEventListener('resize', () => {
        detectSizes();
      });
    };
  }, [bgImage.width, bgImage.height]);

  const animateBG = (target: HTMLElement, size: string, position: string) => {
    setBgSize(size);
    anime({
      targets: target,
      backgroundSize: [bgSize, size],
      backgroundPosition: position,
      duration: 2200,
      easing: 'easeInOutSine',
    });
  };

  const zoomImage = (scene: string) => {
    switch (scene) {
      case 'sustainability':
        animateBG(image.current, '355%', '29% 57.5%');
        break;
      case 'housing':
        animateBG(image.current, '322%', '63% 32%');
        break;
      case 'milking':
        animateBG(image.current, '344%', '66.1% 38%');
        break;
      case 'feeding':
        animateBG(image.current, '400%', '24% 35%');
        break;
      case 'transport':
        animateBG(image.current, '340%', '58% 52%');
        break;
      default:
        break;
    }
  };

  const zoomScene = (scene: string) => {
    setScene('zooming');
    const switchScene = () => {
      switch (scene) {
        case 'sustainability':
          document.title = 'Dairy Tour 360 | Sustainability';
          navigate('/sustainability');
          setScene('sustainability');
          user.setCurrentSegment('sustainability');
          break;
        case 'housing':
          document.title = 'Dairy Tour 360 | Housing';
          navigate('/housing');
          setScene('housing');
          user.setCurrentSegment('housing');
          break;
        case 'milking':
          document.title = 'Dairy Tour 360 | Milking';
          navigate('/milking');
          setScene('milking');
          user.setCurrentSegment('milking');
          break;
        case 'feeding':
          document.title = 'Dairy Tour 360 | Feeding';
          navigate('/feeding');
          setScene('feeding');
          user.setCurrentSegment('feeding');
          break;
        case 'transport':
          document.title = 'Dairy Tour 360 | Transportation & Processing';
          navigate('/transportation&processing');
          setScene('transport');
          user.setCurrentSegment('transport');
          break;
        default:
          break;
      }
    };
    setTimeout(switchScene, 2000);
    zoomImage(scene);
  };

  const zoomOut = () => {
    setScene('zooming');
    setPlayAll(false);
    document.title = 'Dairy Tour 360 | Virtual Farm Experience';
    navigate('');
    setTimeout(() => {
      setScene('main');
    }, 2000);
    setCongrats(false);
    setVideo(false);
    if (image.current) {
      animateBG(image.current, `${originSize}%`, '50% 50%');

      setTimeout(() => {
        setBgSize('100%');
        image.current.style.backgroundSize = 'cover';
      }, 2500);
    }
  };

  const handlePlayAll = (e: any) => {
    e.stopPropagation();
    if (isActive) {
      document.title = 'Dairy Tour 360 | Sustainability';
      navigate('/sustainability');
      setScene('sustainability');
      setPlayAll(true);
      setCongrats(false);
      user.setCurrentSegment('sustainability');
      setVideo(true);
      setActive(false);
      setTimeout(() => {
        setActive(true);
      }, 1000);
    }
  };

  const handleBack = () => {
    setVideo(false);
    if (isPlayAll) {
      setScene('zooming');
      setTimeout(() => {
        setScene('main');
      }, 2000);
      document.title = 'Dairy Tour 360 | Virtual Farm Experience';
      navigate('');
      if (image.current) {
        image.current.style.transform = 'scale(1)';
        image.current.style.backgroundSize = 'cover';
        image.current.style.backgroundPosition = 'center';
      }
    } else {
      zoomImage(scene);
    }
    setPlayAll(false);
  };

  const handleShare = (event: any, media: string) => {
    event.stopPropagation();
    setCongrats(false);
    switch (media) {
      case 'email':
        setMediaResource(mediaRes.email);
        break;
      case 'instagram':
        setMediaResource(mediaRes.instagram);
        break;
      case 'twitter':
        setMediaResource(mediaRes.twitter);
        break;
      case 'facebook':
        setMediaResource(mediaRes.facebook);
        break;

      default:
        break;
    }
  };

  const handleLink = () => {
    window.open('https://www.betterwithdairy.com/', '_blank');
  };

  return (
    <>
      <div
        className={st.container}
        ref={container}
        onClick={() => setMediaResource('')}
      >
        <div
          className={st.image}
          ref={image}
          style={{
            backgroundImage: isMobileOnly
              ? `url(../assets/DairyMax_Final_Mobile.jpg)`
              : '',
          }}
        ></div>
        {isMobile && isPortrait ? null : (
          <div
            className={st.logoContainer}
            style={{
              top: isMobile ? '0.5rem' : '',
              width: scene !== 'main' ? '11rem' : '',
            }}
          >
            {scene !== 'main' ? (
              <div className={st.logo} onClick={zoomOut}></div>
            ) : (
              <div className={st.mainChildContainer}>
                <img src={newMainImage} className={st.mainNewImage}></img>
                <h1 className={st.mainText}>Dairy Tour 360</h1>
                <div className={st.mainButtonTour} onClick={handlePlayAll}>
                  <div className={st.buttonText}>Start video tour</div>
                </div>
              </div>
            )}
          </div>
        )}
        {isVideo ? (
          <div className={st.home} onClick={handleBack}>
            <div></div>
          </div>
        ) : scene !== 'main' ? (
          <div className={st.home} onClick={zoomOut}>
            <div></div>
          </div>
        ) : (
          ''
        )}
        {isVideo ? (
          <VideoComponent
            videoSrc={videoSrc}
            zoomOut={zoomOut}
            setVideo={setVideo}
            isCongrats={isCongrats}
            setCongrats={setCongrats}
            isPlayAll={isPlayAll}
            zoomScene={zoomScene}
          ></VideoComponent>
        ) : null}

        {scene === 'main' && !isVideo ? (
          <FirstPage
            zoomScene={zoomScene}
            setVideo={setVideo}
            scene={scene}
          ></FirstPage>
        ) : null}
        {scene === 'sustainability' && !isVideo ? (
          <Sustainability
            setScene={setScene}
            setVideo={setVideo}
          ></Sustainability>
        ) : null}
        {scene === 'housing' && !isVideo ? (
          <Housing setScene={setScene} setVideo={setVideo}></Housing>
        ) : null}
        {scene === 'feeding' && !isVideo ? (
          <Feeding setScene={setScene} setVideo={setVideo}></Feeding>
        ) : null}
        {scene === 'milking' && !isVideo ? (
          <Milking setScene={setScene} setVideo={setVideo}></Milking>
        ) : null}
        {scene === 'transport' && !isVideo ? (
          <Transport setScene={setScene} setVideo={setVideo}></Transport>
        ) : null}
        {isCongrats ? (
          <Congrats
            zoomOut={zoomOut}
            setScene={setScene}
            setCongrats={setCongrats}
            setVideoSrc={setVideoSrc}
            isPlayAll={isPlayAll}
            zoomImage={zoomImage}
          ></Congrats>
        ) : null}
        {mediaResource ? (
          <MediaPopup
            mediaResource={mediaResource}
            setMediaResource={setMediaResource}
          ></MediaPopup>
        ) : null}
        <div className={st.socialMedia}>
          <div
            className={st.tel}
            onClick={(e) => handleShare(e, 'email')}
          ></div>
          <div
            className={st.inst}
            onClick={(e) => handleShare(e, 'instagram')}
          ></div>
          <div
            className={st.tw}
            onClick={(e) => handleShare(e, 'twitter')}
          ></div>
          <div
            className={st.fb}
            onClick={(e) => handleShare(e, 'facebook')}
          ></div>
          <div className={st.dd} onClick={() => handleLink()}></div>
        </div>
        {isMobile ? (
          <ButterflyFlying isVideo={isVideo}></ButterflyFlying>
        ) : (
          <ButterflyNew></ButterflyNew>
        )}
      </div>
    </>
  );
};

export default Main;

const mediaRes = {
  facebook: {
    popupMedia: 'Facebook',
    mediaUrl: `https://www.facebook.com/sharer/sharer.php?u=https://dairytour360.com&t=DairyTour360`,
    bgSrc: '/assets/Facebook.png',
  },
  email: {
    popupMedia: 'Email',
    mediaUrl: 
      'mailto:?subject=DairyTour360&body=https://www.dairytour360.com',
    bgSrc: '/assets/email.png',
  },
  instagram: {
    popupMedia: 'Instagram',
    mediaUrl: 'https://www.instagram.com/dairymax',
    bgSrc: '/assets/Instagram.png',
  },
  twitter: {
    popupMedia: 'Twitter',
    mediaUrl:
      'https://twitter.com/share?url=https://dairytour360.com&text=DairyTour360',
    bgSrc: '/assets/Twitter.png',
  },
};
