import React, { useState, useEffect } from 'react';
import { isMobile, useMobileOrientation, isAndroid } from 'react-device-detect';
import st from './preloader.module.scss';
import newMainImageMobile from '../assets/LogoLabelUpdated.png';
import ButterflyNew from './ButterflyNew';
import { Progress } from './Progress';

const Preloader = (props: any) => {
  const { isPortrait } = useMobileOrientation();
  const [isVisible, setVisible] = useState<Boolean>(true);
  const [progress, setProgress] = React.useState<number>(0);
  const [isEnter, setEnter] = useState<Boolean>(false);
  const pProgress = Math.round(progress * 100);

  const handleEnter = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (pProgress === 100) {
      setTimeout(() => {
        setEnter(true);
      }, 300);
    }
  }, [pProgress]);
 
  React.useEffect(() => {
    const DURATION = 10000;
    const STEP = 1;
    const interval = setInterval(() => {
      setProgress((i) => {
        const nextValue = i + STEP * 0.01;

        if (nextValue >= 1) {
          clearInterval(interval);
        }

        return nextValue;
      });
    }, DURATION / (100 * STEP));
  }, []);

  return (
    <div className={isVisible ? st.visible : st.unvisible}>   
      <video className={st.image} playsInline autoPlay loop muted>
        <source src='/assets/dmx-cows-eating.mp4' type='video/mp4' />
      </video>
      {isMobile && isPortrait ? null : (
        <div className={st.logoContainer} style={{ top: isAndroid ? "0.2rem" : '' }}>
          <div className={st.mainChildContainer}>
            <img src={newMainImageMobile} className={st.mainNewImage}></img>
            <h1 className={st.mainText}>Dairy Tour 360</h1>
          </div>
        </div>
      )}

      <div className={st.alertContainer} style={{ top: isAndroid ? "25%" : '' }}>
        <div className={st.textTitle}>Virtual Farm Experience</div>
        <div className={st.alertText}>
          We are loading the video landscape. Click or tap to explore the dairy
          farm - and access immersive 360 video tours.
        </div>
        <div className={st.progressContainer}>
          {!isEnter ? (
            <Progress value={pProgress} show={true}></Progress>
          ) : (
            <div className={st.gotButton} onClick={handleEnter}>
              Enter
            </div>
          )}
        </div>
      </div>
      {!isMobile ? <ButterflyNew></ButterflyNew> : null}   
    </div>
  );
};

export default Preloader;
